







































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'Chem1301Lab3Discussion1',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        step1: null,
        step2: null,
        step3: null,
        step4: null,
      },
      rows: [
        {step: '1', input: 'step1'},
        {step: '2', input: 'step2'},
        {step: '3', input: 'step3'},
        {step: '4', input: 'step4'},
      ],
    };
  },
});
